@font-face {
  font-family: revpit;
  src:url("../src/assets/fonts/Azonix.otf");
}

body {
  box-shadow: 0px 35px 70px 0px rgba(95, 95, 95, 0.65), 0px 30px 40px -10px rgba(0, 0, 0, 0.55);
  background: linear-gradient(180deg, rgb(92, 92, 92) 0%, rgb(0, 0, 0) 100%);
}
.card {
  width: 90%;
  height: 80%;
  margin: auto;
  min-height: 512px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  background-image: url(../src/assets/imgs/bg-card.jpg);
  background-size: cover;
}

.card::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity here */
  pointer-events: none; /* Ensure it doesn’t interfere with interactions */
  z-index: 1;
}

@media screen and (max-width: 512px) {
  .card {
    width: 100%;
    height: 100%;
    border-radius: 0;
    box-shadow: none;
  }
}

.header {
  display: flex;
  padding: 48px;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  z-index: 2; 
}

.logo {
  img {
    width: 10em;
    height: 10em;
    z-index: 2; 
  }
  
}

.logo:hover { 
  opacity: 0.75;
}

/* .social {
  display: flex;
}

.social a {
  display: inline-block;
  margin-right: 12px;
  transition: opacity 0.05s ease;
}

.social a:last-child {
  margin-right: 0;
}

.social a:hover {
  opacity: 0.75;
}

.social .icon {
  width: 18px;
  fill: #fff;
} */

.content {
  flex: 1 1 auto;
  min-height: 256px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2; 
}

.content .title-holder {
  color: #ffffff;
  text-align: center;
  margin-bottom: 24px;
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);


}

.content .title-holder h1 {
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 12px;
  font-family: revpit;
}

.content .title-holder p {
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.75);
}

@media screen and (max-width: 768px) {
  .content .title-holder {
    max-width: 80%;
  }
}

.content .cta {
  min-width: 64px;
  padding: 16px 24px;
  background: #fff;
  border-radius: 50px;
  cursor: pointer;
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  transform: none;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s cubic-bezier(0.25, 0.25, 0.315, 1.35), transform 0.1s linear;
}

.content .cta:hover {
  transform: translateY(-1px);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.3);
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 48px;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

a.underlined:hover {
  border-bottom: 1px dotted #fff;
}

